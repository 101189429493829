/* General App */
html {
    font-size: 12px;
}

html body {
    background-color: rgb(240, 240, 240);
    padding-bottom: 1rem;

}

.App {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.p-post-info {
    font-size: 1.2rem;
}

.article-subtitle {
    font-size: 1.5rem;
}

.pre-inherit-format {
    white-space: pre-wrap;
    font-family: inherit;
    font-size: 1rem;
}

.galore-purple-bg,
.homepage-section.galore-purple-bg {
    background-color: rgb(97, 38, 144);
}

.white-text,
.white-text .h3-white-text {
    text-decoration: none;
    color: white;
}

.white-text:hover {
    text-decoration: none;
    color: white;
}

.black-text {
    text-decoration: none;
    color: black;
}

.black-text:hover {
    color: black;
}

.homepage-section h1,
.homepage-section h2,
.homepage-section h3,
.homepage-section h4,
.homepage-section h5,
.homepage-section h6,
.homepage-card h1,
.homepage-card h2,
.homepage-card h3,
.homepage-card h4,
.homepage-card h5,
.homepage-card h6 {
    color: teal;
}

/* Navbar */
.navbar-brand-img {
    width: 2rem;
    height: 2rem;
}


.nav-item-selected {
    background-color: rgb(233, 190, 253);
}

.galore-purple-bg .nav-item-selected .nav-link-text-selected {
    color: black;
}

.galore-purple-bg .nav-item-outer .nav-link-text {
    color: white;
}

.nav-item-outer:hover {
    background-color: rgb(50, 0, 91);
}

/* .galore-purple-bg .nav-link-text:hover {
    color: white;
} */

.galore-purple-bg .navbar-toggler-background {
    background-color: white;
}


/* Home Page */
.homepage-section {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    margin: 1rem;
    background-color: white;
}

.homepage-section-no-border {
    padding: 1rem;
}

.homepage-card {
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 1rem;
    word-wrap: break-word;
    overflow-wrap: anywhere;
}

.homepage-section-no-border .homepage-card-pl-0 {
    padding: 0 1rem 0 0;
    display: flex;
    justify-content: flex-start;
}

.homepage-section-no-border .homepage-card-pr-0 {
    padding: 0 0 0 1rem;
    display: flex;
    justify-content: flex-end;
}

.homepage-section-no-border .homepage-card-px-0 {
    padding: 0;
}

.homepage-card-pr-0 .homepage-card,
.homepage-card-pl-0 .homepage-card {
    width: 95%;
}

.homepage-card-px-0 .homepage-card {
    width: 100%;
}

.homepage-card img {
    height: 300px;
    object-fit: cover;
    object-position: center;
}

.explore-main-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.explore-items-img,
.top-rated-img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    margin: 1rem;
}

.homepage-explore-list-items {
    list-style: none;
    border-bottom: 1px solid #ccc;
}


@media (min-width: 992px) {
    .homepage-section-no-border .homepage-card-pl-0 {
        padding: 0 1rem 0 0;
        display: flex;
        justify-content: flex-start;
    }

    .homepage-section-no-border .homepage-card-pr-0 {
        padding: 0 0 0 1rem;
        display: flex;
        justify-content: flex-end;
    }
}

@media (max-width: 992px) {

    .homepage-section-no-border .homepage-card-pl-0 {
        padding: 0 0 1rem 0;
    }

    .homepage-section-no-border .homepage-card-pr-0 {
        padding: 1rem 0 0 0;
    }
}



/* Galore Posts */
.galore-posts-highlight-container {
    position: relative;
    width: 100%;
    height: 20rem;
}

.galore-posts-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.galore-posts-highlight-container img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.galore-posts-image-text {
    position: absolute;
    top: 40%;
    left: 5%;
    color: white;
    font-size: 2rem;
    z-index: 10;
}

.galore-posts-styles-inherit {
    text-decoration: none;
    color: inherit;
}

/* .galore-post-paragraph {
    font-size: 22px;
} */

.galore-post-img {
    max-width: 100%;
    max-height: 30rem;
    object-fit: cover;
    display: block;
    margin: 0 auto 15px auto;
}

.search-table {
    text-align: center;
    vertical-align: middle;
}

.search-table thead,
.search-table tbody {
    font-size: '1.5rem';
}

@media (max-width: 992px) {
    .search-table {
        overflow-x: auto;
    }
}

td {
    vertical-align: middle;
}

@media (max-width: 1200px) {
    .top-rated-center-align {
        flex-direction: column;
        text-align: center;
    }

    .top-rated-text {
        margin-bottom: 1rem;
    }

    .top-rated-img {
        margin: 1rem auto !important;
    }
}

@media (max-width: 768px) {
    .top-rated-xs-marginbottom {
        margin-bottom: 25px;
    }
}



/* Make Post */
.make-post-div-outer {
    display: flex;
    align-items: center;
}

.make-post-div-outer .make-post-h4 {
    margin: 0 1rem;
}

.image-selection-div-outer {
    position: relative;
    display: inline-block;
    margin: 0.5rem auto;
}

.image-selection-img {
    position: relative;
    width: 100%;
    height: 20rem;
    object-fit: cover;
}

.blue-text-remove-file {
    color: blue;
    display: inline-block;
    text-decoration: underline;
    margin-top: 1rem;
    cursor: pointer;
}

.image-upload-preview {
    width: 25%;
    height: auto;
    object-fit: cover;
}

.bg-danger.btn-border-none,
.bg-success.btn-border-none,
.bg-primary.btn-border-none {
    border: none;
}